export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'SOLSHERPAS X COTH',
  headline: 'Solsherpas X Chronicles of the Himalayas - Play2earn game',
  description:
    'Own your courageous and brave solsherpas NFT and be early in Chronicles of the Himalayas (COTH Metaverse) ',
  buttonLabel: 'Try COTH Demo',
  buttonLink: 'https://google.com',
  imgStart: false,
  img: require('../../images/explore.svg'),
  alt: 'Car',
  dark: true,
  primary: true,
  darkText: false
};

export const homeObjTwo = {
  id: 'metaverse',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'COTH Metaverse',
  headline: 'Unlock your Sherpa inside you in COTH',
  description:
    'COTH - Chronicles of the Himalayas is a poly 3D game under development which will officially be Metaverse in the Himalayas for Solsherpas NFTs. We plan to develop our products with active involvement of our community. More details will be announced on Discord.',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: require('../../images/metaverse.svg'),
  alt: 'Piggybank',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjThree = {
  id: 'faq',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Roadmaps and FAQs',
  headline: 'Roadmaps and FAQs coming soon!',
  description:
    "We are constantly working on our Roadmap and FAQs. We will update our website ASAP. Please join Discord for instant updates.",
  buttonLabel: 'Learn More',
  imgStart: false,
  img: require('../../images/faq.svg'),
  alt: 'Papers',
  dark: false,
  primary: false,
  darkText: true
};
