import React from 'react';
import Cashew from '../../images/cashew.png';
import Srothcode from '../../images/srothcode.jpeg';
import You from '../../images/you.svg'
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './ServicesElements';

const Services = () => {
  return (
    <ServicesContainer id='team'>
      <ServicesH1>Our Team</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Cashew} />
          <ServicesH2>Cashew</ServicesH2>
          <ServicesP>
            He is Creative and charming Software Engineer, Blockchain Enthusiastic, and Entrepreneur 
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Srothcode} />
          <ServicesH2>Srothcode Games</ServicesH2>
          <ServicesP>
            Srothcode Games is a gaming company with 5+ years of experience in gaming and software development. 
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={You} />
          <ServicesH2>You</ServicesH2>
          <ServicesP>
            We are constantly expanding our team and next could be you. Please get in touch on Discord if you are interested.
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
