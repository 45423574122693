import React from 'react';
import {
  FaTwitter,
  FaDiscord
} from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink
} from './FooterElements';

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Information</FooterLinkTitle>
              <FooterLink to='/'>FAQ</FooterLink>
              <FooterLink to='/'>Chronicles of the Himalayas</FooterLink>
              <FooterLink to='/'>How to Mint</FooterLink>
              <FooterLink to='/'>Rarity</FooterLink>
            </FooterLinkItems>
            
          </FooterLinksWrapper>
          <FooterLinksWrapper>
          <FooterLinkItems>
              <FooterLinkTitle>Contact Us</FooterLinkTitle>
              <FooterLink to='/'>Contact</FooterLink>
              <FooterLink to='/'>Support</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to='/' onClick={toggleHome}>
              SOLSHERPAS
            </SocialLogo>
            <WebsiteRights>Solsherpas © 2021 All rights reserved.</WebsiteRights>
            <SocialIcons>
              <SocialIconLink
                target='_blank'
                aria-label='Twitter'
                href='https://twitter.com/solsherpas'
              > 
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink 
                href='https://discord.gg/Z3PgXHnKBU' 
                target='_blank' 
                aria-label='Discord'
              >
                <FaDiscord />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
